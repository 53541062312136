import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducer';
import { loadState, saveState } from './localStorage';
import { dispatchMiddleware, localStorageMiddleware } from './middleware';

export function getStore() {
  if (window.og && window.og.store) return window.og.store;

  const isPreviewMode = window.og && window.og.previewMode;

  const composeEnhancers =
    // eslint-disable-next-line no-underscore-dangle
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? // eslint-disable-next-line no-underscore-dangle
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        })
      : compose;

  const middlewares = [thunk, dispatchMiddleware];
  if (!isPreviewMode) {
    middlewares.push(localStorageMiddleware);
  }

  const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  const store = createStore(reducer, isPreviewMode ? {} : loadState(), enhancer);

  window.og = window.og || {};
  window.og.store = store;
  return store;
}

export default getStore;
