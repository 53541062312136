import { LitElement, css, html } from 'lit-element';

export class Select extends LitElement {
  static get styles() {
    return css`
      :host {
        display: inline-block;
        color: inherit;
        position: relative;
        height: 100%;
        cursor: inherit;
        font-family: inherit;
        font-weight: inherit;
      }
      select {
        font-weight: inherit;
        display: block;
        height: 100%;
        cursor: inherit;
        color: inherit;
        font-family: inherit;
        font-size: 1em;
        line-height: 1.3;
        padding: var(--og-select-padding, 0.4em 1.8em 0.3em 0.5em);
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        margin: 0;
        border: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-color: transparent;
      }
      select::-ms-expand {
        display: none;
      }
      select:hover {
        // border-color: #888;
      }
      select:focus {
        // border-color: #aaa;
        // box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
        // box-shadow: 0 0 0 3px -moz-mac-focusring;
        // color: #222;
        outline: none;
      }
      select option {
        font-weight: inherit;
      }
      span {
        position: absolute;
        // background: white;
        color: inherit;
        fill: white;
        pointer-events: none;
        right: 0.3em;
        top: 50%;
        z-index: 1;
        font-size: 1em;
        line-height: 0.2em;
        transform: scaleY(0.5);
      }
    `;
  }

  static get properties() {
    return {
      options: { type: Array },
      selected: { type: String }
    };
  }

  render() {
    return html`
      <select @change="${this.onChange}" .value="${this.selected}">
        ${this.options.map(
          option => html`
            <option value="${option.value}" ?selected=${this.selected === option.value}>${option.text}</option>
          `
        )}
      </select>
      <span>&#9660;</span>
      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="36" height="100%" viewBox="0 0 36 36">
        <path d="M10.5 15l7.5 7.5 7.5-7.5z" />
        <path d="M0 0h36v36h-36z" fill="none" />
      </svg> -->
    `;
  }
}

export default Select;
