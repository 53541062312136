import { html } from 'lit-element';

import { optinProduct, optoutProduct } from '../core/actions';
import { OptinStatus, mapStateToProps } from './OptinStatus';
import { connect } from '../core/connect';

export class OptinToggle extends OptinStatus {
  static get properties() {
    return {
      ...super.properties,
      frequency: { type: String }
    };
  }

  handleClick(ev) {
    if (this.subscribed) this.optoutProduct(this.product);
    else this.optinProduct(this.product, this.frequency || this.defaultFrequency);
    ev.preventDefault();
  }

  render() {
    return html`
      <slot name="default">
        <button id="action-trigger" class="btn checkbox ${this.subscribed ? 'active' : ''}"></button>
        <label for="action-trigger">
          <slot>
            <slot name="label"><og-text key="offerOptInLabel"></og-text></slot
          ></slot>
        </label>
      </slot>
    `;
  }
}

export const ConnectedOptinToggle = connect(mapStateToProps, { optoutProduct, optinProduct })(OptinToggle);

export default ConnectedOptinToggle;
