import { createIsMessageAllowed } from './core/createIsMessageAllowed';

const allowedOrigin = [
  'https://rc3.ordergroove.com',
  'https://rc3.stg.ordergroove.com',
  'http://localhost:3000',
  window.location.origin
];

const createBroadcastMessage = opener => (ogType, data) => {
  allowedOrigin.forEach(target => opener.postMessage({ ogType, ...data }, target));
};

function handleReady(ev) {
  const isMessageAllowed = createIsMessageAllowed(allowedOrigin);
  const broadcastMessage = createBroadcastMessage(ev.source);

  if (isMessageAllowed(ev)) {
    if (ev.data.ogType === 'READY') {
      import(`${window.OG_CDN_PUBLIC_PATH || './'}client.js`).then(({ initializeClient }) => {
        initializeClient(isMessageAllowed, broadcastMessage);
        window.removeEventListener('message', handleReady);
      });
    }
  }
}
export function offersLiveEditor(opener = window.opener) {
  if (opener && opener !== window) {
    window.addEventListener('message', handleReady);
    createBroadcastMessage(opener)('READY');
  }
}

export default offersLiveEditor;
