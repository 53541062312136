import { offersLiveEditor } from '@ordergroove/offers-live-editor';
import { setStore } from './core/connect';
import { getStore } from './core/store';
import { listenLocalStorageChanges } from './core/localStorage';
import * as adapters from './core/adapters';
import * as actions from './core/actions';
import { ConnectedWhen } from './components/When';
import { ConnectedOptinButton } from './components/OptinButton';
import { ConnectedOptoutButton } from './components/OptoutButton';
import { ConnectedOptinSelect } from './components/OptinSelect';
import { ConnectedUpsellButton } from './components/UpsellButton';
import { ConnectedUpsellModal } from './components/UpsellModal';
import { ConnectedOptinToggle } from './components/OptinToggle';
import { ConnectedOptinStatus } from './components/OptinStatus';
import { ConnectedText } from './components/Text';
import { ConnectedIncentiveText } from './components/IncentiveText';
import { ConnectedSelectFrequency } from './components/SelectFrequency';
import { ConnectedNextUpcomingOrder } from './components/NextUpcomingOrder';
import { ConnectedOffer } from './components/Offer';
import { Modal } from './components/Modal';
import { Select } from './components/Select';
import { Tooltip } from './components/Tooltip';
import { ConnectedFrequencyStatus } from './components/FrequencyStatus';
import * as testMode from './test-mode';
import { api } from './core/api';
import { environment, offer } from './core/reducer';
import { RECEIVE_PRODUCT_PLANS } from './core/constants';
import ConnectedPrice from './components/Price';

testMode.enable();
let store;

export const offers = {};

export function setEnvironment(e) {
  store.dispatch(actions.setEnvironment(e));
  return offers;
}
export function setMerchantId(m) {
  store.dispatch(actions.setMerchantId(m));
  return offers;
}
export function setAuthUrl(authUrl) {
  store.dispatch(actions.setAuthUrl(authUrl));
  return offers;
}
export function getProductsForPurchasePost(productIds = []) {
  return adapters.getProductsForPurchasePost(store.getState(), productIds);
}
export function getOptins(productIds = []) {
  return adapters.getProductsForPurchasePost(store.getState(), productIds);
}
export function clear() {
  store.dispatch(actions.checkout());
}
export function addOptinChangedCallback(fn) {
  if (typeof fn === 'function') document.addEventListener('optin-changed', e => fn(e.detail));
}
export function disableOptinChangedCallbacks() {
  document.addEventListener('optin-changed', e => e.stopPropagation(), true);
}
export function register() {
  try {
    customElements.define('og-when', ConnectedWhen);
    customElements.define('og-text', ConnectedText);
    customElements.define('og-incentive-text', ConnectedIncentiveText);
    customElements.define('og-offer', ConnectedOffer);
    customElements.define('og-select-frequency', ConnectedSelectFrequency);
    customElements.define('og-optout-button', ConnectedOptoutButton);
    customElements.define('og-optin-toggle', ConnectedOptinToggle);
    customElements.define('og-optin-status', ConnectedOptinStatus);
    customElements.define('og-optin-button', ConnectedOptinButton);
    customElements.define('og-optin-select', ConnectedOptinSelect);
    customElements.define('og-upsell-button', ConnectedUpsellButton);
    customElements.define('og-frequency-status', ConnectedFrequencyStatus);
    customElements.define('og-modal', Modal);
    customElements.define('og-select', Select);
    customElements.define('og-tooltip', Tooltip);
    customElements.define('og-upsell-modal', ConnectedUpsellModal);
    customElements.define('og-next-upcoming-order', ConnectedNextUpcomingOrder);
    customElements.define('og-price', ConnectedPrice);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err);
  }
  offers.register = () => 0;
}
export function previewMode(set) {
  window.og = window.og || {};
  if (set === false) {
    delete window.og;
  } else {
    window.og.previewMode = true;
  }
  return this;
}
export function config(configuration) {
  store.dispatch(actions.setConfig(configuration));
  return offers;
}
export function setLocale(locale) {
  store.dispatch(actions.setLocale(locale));
  return offers;
}
export function addTemplate(tagName, content, configOption) {
  store.dispatch(actions.addTemplate(tagName, content, configOption));
  return offers;
}
/**
 * templates object where keys are selectors and values are content
 */
export function setTemplates(templates) {
  store.dispatch(actions.setTemplates(templates));
  return offers;
}
export function setPublicPath(publicPath) {
  return offers;
}
export function resolveSettings(merchantId, env, settings, storeInstance = store) {
  if (merchantId && env && settings) {
    let products = [];
    if (settings.product) {
      products.push(settings.product);
    } else if (settings.cart && Array.isArray(settings.cart.products)) {
      products = products.concat(settings.cart.products);
    }
    const { apiUrl } = environment({}, actions.setEnvironment(env));
    const { sessionId } = storeInstance.getState();
    if (sessionId) {
      products.forEach(product => {
        api.fetchOffer(apiUrl, merchantId, sessionId, `${product}`, 'pdp');
      });
    }

    if (settings.product_discounts && typeof settings.product_discounts === 'object') {
      storeInstance.dispatch({ type: RECEIVE_PRODUCT_PLANS, payload: settings.product_discounts });
    }
  }
}

/**
 * Initialize OG object
 * @param {*} merchantId
 * @param {*} env
 * @param {*} authUrl
 */
export function initialize(merchantId, env, authUrl) {
  if (offers.isReady) {
    console.warn('og.offers has been initialized already. Skipping.');
    return offers;
  }

  store = getStore();

  offers.store = store;
  offers.resolveSettings(merchantId, env, window.og_settings, store);

  setStore(store);

  if (merchantId) offers.setMerchantId(merchantId);
  if (env) offers.setEnvironment(env);
  if (authUrl) offers.setAuthUrl(authUrl);

  window.addEventListener('storage', listenLocalStorageChanges(store));

  if (merchantId && env) {
    store.dispatch(actions.requestSessionId());
    store.dispatch(actions.fetchAuth());
    offers.register();
  }

  offers.isReady = true;

  return offers;
}

export default initialize;

Object.assign(offers, {
  setEnvironment,
  setMerchantId,
  setAuthUrl,
  getProductsForPurchasePost,
  getOptins,
  clear,
  addOptinChangedCallback,
  disableOptinChangedCallbacks,
  register,
  previewMode,
  config,
  setLocale,
  addTemplate,
  setTemplates,
  setPublicPath,
  resolveSettings,
  initialize
});

window.OG = window.OG || {};
Object.assign(window.OG, offers);
Object.assign(offers.initialize, offers);

offersLiveEditor();

// // use this syntax to allow es6 module be called as default function og.offers(...)
// module.exports = offers.initialize;
