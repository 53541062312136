/* eslint-disable no-console */
import { LitElement } from 'lit-element';

export const sanitizeFrequencyString = value => {
  const matchDwm = String(value || '')
    .trim()
    .match(/(\d+)\s*([dwm])/);

  if (matchDwm) {
    return `${matchDwm[1]}_${{ d: 1, w: 2, m: 3 }[matchDwm[2]]}`;
  }
  return value;
};

export const buildProduct = element =>
  element.hasAttribute('product') && {
    id: element.getAttribute('product'),
    ...(element.hasAttribute('product-components') && {
      components: JSON.parse(element.getAttribute('product-components'))
    })
  };

export const resolveFromParentElement = (element, key) => {
  let offer;
  let ref = element;
  while (ref) {
    if (ref instanceof LitElement && ref.tagName.startsWith('OG-') && ref.hasAttribute(key)) {
      return ref;
    }
    ref = ref.nodeType === 11 ? ref.host : ref.parentNode;
  }
  if (!offer) {
    console.warn(`No OG parent element found that has attribute [${key}]`, element);
  }
  return offer;
};

export const resolveProduct = element => {
  let product = buildProduct(element);
  if (!product) {
    const offer = element.offer;
    if (offer) {
      product = buildProduct(offer);
    }
  }
  return product;
};

export const resolveOffer = element => {
  let ref = element;
  while (ref) {
    if (ref.tagName === 'OG-OFFER') {
      return ref;
    }
    ref = ref.nodeType === 11 ? ref.host : ref.parentNode;
  }
  return undefined;
};

export const withOfferTemplate = Base =>
  class extends Base {
    get offer() {
      return resolveOffer(this);
    }

    connectedCallback() {
      super.connectedCallback();
      this.offersChangeTemplate = this.offersChangeTemplate.bind(this);
      if (this.offer) {
        this.offer.addEventListener('template-changed', this.offersChangeTemplate);
      }
    }

    disconnectedCallback() {
      super.disconnectedCallback();
      if (this.offer) {
        this.offer.removeEventListener('template-changed', this.offersChangeTemplate);
      }
    }

    offersChangeTemplate() {
      this._enqueueUpdate();
    }
  };

export const withProduct = Base =>
  class extends withOfferTemplate(Base) {
    get product() {
      return resolveProduct(this);
    }
  };

export const withChildOptions = Base =>
  class extends Base {
    get childOptions() {
      return this._childOptions || { options: [] };
    }

    connectedCallback() {
      const options = [];
      let isSelected = null;
      this.querySelectorAll('option').forEach(it => {
        const value = sanitizeFrequencyString(it.value);
        const text = it.innerText.trim();
        options.push({ value, text });

        if (!isSelected && it.selected) {
          isSelected = value;
        }
      });
      this._childOptions = { options, isSelected };
      super.connectedCallback && super.connectedCallback();
    }
  };
