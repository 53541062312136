import { throttle } from 'throttle-debounce';
import * as constants from './constants';
import { isSameProduct } from './selectors';
import { saveState } from './localStorage';

export const dispatchEvent = (name, detail, el = document) =>
  el.dispatchEvent(
    new CustomEvent(name, {
      detail
    })
  );

export const dispatchOptinChangedEvent = optedIn => ({
  payload: { product: { id: productId, components } = {} } = {}
} = {}) =>
  setTimeout(
    () =>
      dispatchEvent('optin-changed', {
        productId,
        components,
        optedIn
      }),
    0
  );

export const conditionals = [
  {
    expressions: [
      ({ type } = {}) => type === constants.OPTIN_PRODUCT,
      ({ type } = {}) => type === constants.PRODUCT_CHANGE_FREQUENCY
    ],
    fn: dispatchOptinChangedEvent(true)
  },
  {
    expressions: [({ type } = {}) => type === constants.OPTOUT_PRODUCT],
    fn: dispatchOptinChangedEvent(false)
  }
];

export const dispatchMiddleware = store => next => action => {
  const state = store.getState();
  conditionals.forEach(conditional => {
    if (conditional.expressions.some(expression => expression(action, state))) conditional.fn(action);
  });

  next(action);
};

export const localStorageMiddleware = store => next => action => {
  next(action);

  const callToSave = throttle(500, () => {
    saveState({
      ...store.getState()
    });
  });

  if (action.type !== constants.LOCAL_STORAGE_CHANGE) {
    callToSave();
  }
};
