import { LitElement, html } from 'lit-element';
import { connect } from '../core/connect';
import { withProduct } from '../core/resolveProperties';

export class DiscountAmount {
  constructor(value) {
    this.value = value;
    this.className = 'DiscountAmount';
  }

  toString() {
    return `${this.value}`;
  }
}

class DiscountPercent extends DiscountAmount {
  constructor(value) {
    super(value);
    this.className = 'DiscountPercent';
  }

  toString() {
    return `${super.toString()}%`;
  }
}

class ShippingDiscountPercent extends DiscountPercent {
  constructor(value) {
    super(value);
    this.className = 'ShippingDiscountPercent';
  }

  toString() {
    return this.value === 100 ? 'free shipping' : super.toString();
  }
}

const DISCOUNT_PERCENT = 'Discount Percent';
const DISCOUNT_AMOUNT = 'Discount Amount';
const TOTAL_PRICE = 'total_price';
const SHIPPING_TOTAL = 'shipping_total';
const SUB_TOTAL = 'sub_total';

const discountBuilder = ({ field, object, type, value }) => {
  const supportedDiscounts = [
    [new DiscountPercent(value), { field: TOTAL_PRICE, object: 'item', type: DISCOUNT_PERCENT }],
    [new DiscountAmount(value), { field: TOTAL_PRICE, object: 'item', type: DISCOUNT_AMOUNT }],
    [new ShippingDiscountPercent(value), { field: SHIPPING_TOTAL, object: 'order', type: DISCOUNT_PERCENT }],
    [new DiscountAmount(value), { field: SHIPPING_TOTAL, object: 'order', type: DISCOUNT_AMOUNT }],
    [new DiscountPercent(value), { field: SUB_TOTAL, object: 'order', type: DISCOUNT_PERCENT }],
    [new DiscountAmount(value), { field: SUB_TOTAL, object: 'order', type: DISCOUNT_AMOUNT }]
  ];

  const available = supportedDiscounts.find(([, it]) => it.field === field && it.object === object && it.type === type);
  return available && available[0];
};

export const getTransformedDiscounts = discounts => {
  return discounts.map(discount => discountBuilder(discount)).filter(discount => discount !== undefined);
};

export function filterIncentives(incentive) {
  if (discountBuilder(incentive).className !== this.incentiveClass) {
    return false;
  }

  if (this.incentiveValue && this.incentiveValue.toString() !== incentive.value.toString()) {
    return false;
  }

  return true;
}

export function replaceText(acc, curr) {
  return acc.replace(this.textToReplace, discountBuilder(curr));
}

export class IncentiveText extends withProduct(LitElement) {
  static get properties() {
    return {
      ...super.properties,
      incentives: { type: Object, attribute: false },
      from: { type: String },
      label: { type: String },
      initial: { type: Boolean, default: false },
      value: { type: Number }
    };
  }

  createRenderRoot() {
    return this;
  }

  render() {
    const incentiveClass = this.from;
    const incentiveValue = this.value;
    const incentiveType = this.initial ? 'initial' : 'ongoing';

    const incentive = (this.incentives[incentiveType] || []).find(
      filterIncentives.bind({ incentiveClass, incentiveValue })
    );

    return html`
      ${this.label} ${incentive ? discountBuilder(incentive) : this.renderFallback()}
    `;
  }

  renderFallback() {
    const incentiveClass = this.from;
    const incentiveValue = this.value;
    const incentiveType = this.initial ? 'initial' : 'ongoing';
    return html`
      ${discountBuilder({
        field: 'sub_total',
        object: 'order',
        type: 'Discount Percent',
        value: this.value
      })}
    `;
  }
}

export const mapStateToProps = (state, ownProps) => ({
  incentives: (state.incentives || {})[ownProps && ownProps.product && ownProps.product.id] || {}
});

export const ConnectedIncentiveText = connect(mapStateToProps)(IncentiveText);

export default ConnectedIncentiveText;
