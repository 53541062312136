import { html } from 'lit-element';
import { optoutProduct } from '../core/actions';
import { OptinStatus, mapStateToProps } from './OptinStatus';
import { connect } from '../core/connect';

export class OptoutButton extends OptinStatus {
  static get properties() {
    return {
      ...super.properties,
      label: { type: String }
    };
  }

  handleClick(ev) {
    this.optoutProduct(this.product);
    ev.preventDefault();
  }

  render() {
    return html`
      <slot name="default">
        <button id="action-trigger" class="btn radio ${this.subscribed ? '' : 'active'}"></button>
        <label for="action-trigger">
          <slot>
            <og-text key="offerOptOutLabel"></og-text>
          </slot>
        </label>
      </slot>
    `;
  }
}

export const ConnectedOptoutButton = connect(mapStateToProps, { optoutProduct })(OptoutButton);

export default ConnectedOptoutButton;
