import { html, css } from 'lit-element';
import { productChangeFrequency, optoutProduct } from '../core/actions';
import { withChildOptions } from '../core/resolveProperties';
import { OptinStatus, mapStateToProps as mapStateToPropsOptinStatus } from './OptinStatus';
import { mapStateToProps, frequencyText } from './FrequencyStatus';
import { connect } from '../core/connect';
import { defaultFrequency } from '../core/props';

export class OptinSelect extends withChildOptions(OptinStatus) {
  static get properties() {
    return {
      ...super.properties,
      frequencies: { type: Array, attribute: false },
      frequency: { type: String },
      defaultFrequency
    };
  }

  static get styles() {
    return css`
      :host {
        display: inline-block;
        cursor: pointer;
        background-color: var(--og-select-bg-color, #fff);
        border: var(--og-select-border, 1px solid #aaa);
        border-radius: var(--og-select-border-radius, 0.5em);
        border-width: var(--og-select-border-width, 1px);
        box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
      }
    `;
  }

  get isSelected() {
    return this.subscribed ? this.frequency : 'optedOut';
  }

  onOptinChange(value) {
    if (value === 'optedOut') {
      this.optoutProduct(this.product);
    } else {
      this.productChangeFrequency(this.product, value);
    }
  }

  render() {
    const { options: childOptions, isSelected } = this.childOptions;
    let options = childOptions;
    if (!childOptions.length) {
      options = [
        {
          value: 'optedOut',
          text: html`
            <og-text key="offerOptOutLabel"></og-text>
          `
        },
        ...(this.frequencies || []).map(value => ({
          value,
          text: frequencyText(value, this.defaultFrequency)
        }))
      ];
    }

    return html`
      <og-select
        .options="${options}"
        .selected="${isSelected}"
        .onChange="${({ target: { value } }) => this.onOptinChange(value)}"
      ></og-select>
    `;
  }
}

export const ConnectedOptinSelect = connect(
  (state, ownProps) => ({
    ...mapStateToPropsOptinStatus(state, ownProps),
    ...mapStateToProps(state, ownProps)
  }),
  { productChangeFrequency, optoutProduct }
)(OptinSelect);

export default ConnectedOptinSelect;
