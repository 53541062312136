import { html } from 'lit-element';
import { connect } from '../core/connect';

import { withProduct } from '../core/resolveProperties';
import { TemplateElement } from '../core/base';
import { makeProductFrequencySelector } from '../core/selectors';

export class Price extends withProduct(TemplateElement) {
  static get properties() {
    return {
      ...super.properties,
      regular: { type: Boolean, reflect: true },
      subscription: { type: Boolean, reflect: true },
      discount: { type: Boolean, reflect: true },
      frequency: { type: Object },
      productPlans: { type: Object }
    };
  }

  get value() {
    const frequency = this.frequency || (this.offer && this.offer.defaultFrequency);
    const plans = this.productPlans[this.product.id] || {};
    const currentPlan = plans[frequency] || [];
    if (!currentPlan) return '';
    const [regularPrice, discountRate, subscriptionPrice] = currentPlan;
    if (subscriptionPrice === regularPrice) return '';

    if (this.regular) {
      return regularPrice;
    }
    if (this.discount) return discountRate;
    return subscriptionPrice;
  }

  render() {
    const value = this.value;
    if (value)
      return html`
        <slot name="prepend"></slot>
        ${value}
        <slot name="append"></slot>
      `;

    return html`
      <slot></slot>
    `;
  }
}
const mapStateToProps = (state, ownProps) => ({
  productPlans: state.productPlans,
  frequency: makeProductFrequencySelector(ownProps.product)(state)
});

export default connect(mapStateToProps)(Price);
