export const OPTIN_PRODUCT = 'OPTIN_PRODUCT';
export const OPTOUT_PRODUCT = 'OPTOUT_PRODUCT';
export const PRODUCT_CHANGE_FREQUENCY = 'PRODUCT_CHANGE_FREQUENCY';
export const SET_MERCHANT_ID = 'SET_MERCHANT_ID';
export const REQUEST_OFFER = 'REQUEST_OFFER';
export const RECEIVE_OFFER = 'RECEIVE_OFFER';
export const PRODUCT_HAS_CHANGED = 'PRODUCT_HAS_CHANGED';
export const CREATED_SESSION_ID = 'CREATED_SESSION_ID';
export const SET_AUTH_URL = 'SET_AUTH_URL';
export const REQUEST_AUTH = 'REQUEST_AUTH';
export const AUTHORIZE = 'AUTHORIZE';
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const REQUEST_ORDERS = 'REQUEST_ORDERS';
export const RECEIVE_ORDERS = 'RECEIVE_ORDERS';

export const RECEIVE_ORDER_ITEMS = 'RECEIVE_ORDER_ITEMS';
export const FETCH_RESPONSE_ERROR = 'FETCH_RESPONSE_ERROR';
export const SET_ENVIRONMENT_STAGING = 'SET_ENVIRONMENT_STAGING';
export const SET_ENVIRONMENT_DEV = 'SET_ENVIRONMENT_DEV';
export const SET_ENVIRONMENT_PROD = 'SET_ENVIRONMENT_PROD';
export const CONCLUDE_UPSELL = 'CONCLUDE_UPSELL';
export const REQUEST_CREATE_IU_ORDER = 'REQUEST_CREATE_IU_ORDER';
export const CREATE_ONE_TIME = 'CREATE_ONE_TIME';
export const REQUEST_CONVERT_ONE_TIME = 'REQUEST_CONVERT_ONE_TIME';
export const CONVERT_ONE_TIME = 'CONVERT_ONE_TIME';
export const NO_UPCOMING_ORDER_ERROR = 'NO_UPCOMING_ORDER_ERROR';
export const CHECKOUT = 'CHECKOUT';
export const RECEIVE_FETCH = 'RECEIVE_FETCH';
export const SET_LOCALE = 'SET_LOCALE';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_PREVIEW_STANDARD_OFFER = 'SET_PREVIEW_STANDARD_OFFER';
export const SET_PREVIEW_UPSELL_OFFER = 'SET_PREVIEW_UPSELL_OFFER';
export const ADD_TEMPLATE = 'ADD_TEMPLATE';
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const LOCAL_STORAGE_CHANGE = 'LOCAL_STORAGE_CHANGE';
export const LOCAL_STORAGE_CLEAR = 'LOCAL_STORAGE_CLEAR';
export const SET_FIRST_ORDER_PLACE_DATE = 'SET_FIRST_ORDER_PLACE_DATE';
export const SET_PRODUCT_TO_SUBSCRIBE = 'SET_PRODUCT_TO_SUBSCRIBE';
export const RECEIVE_PRODUCT_PLANS = 'RECEIVE_PRODUCT_PLANS';
